// src/App.js

import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import './App.css';

function App() {
  const [weeklyStats, setWeeklyStats] = useState([]);
  const [averageAttempts, setAverageAttempts] = useState([]);
  const [darkMode, setDarkMode] = useState(false);

  // Load theme from localStorage
  useEffect(() => {
    const savedTheme = localStorage.getItem('darkMode') === 'true';
    setDarkMode(savedTheme);
  }, []);

  // Save theme to localStorage
  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  useEffect(() => {
    // Load weekly stats CSV
    Papa.parse('/weekly-stats.csv', {
      download: true,
      header: true,
      encoding: 'UTF-8',
      complete: function (results) {
        setWeeklyStats(results.data);
      },
    });

    // Load average attempts CSV
    Papa.parse('/average-attempts.csv', {
      download: true,
      header: true,
      encoding: 'UTF-8',
      complete: function (results) {
        setAverageAttempts(results.data);
      },
    });
  }, []);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <div className={darkMode ? 'dark-mode' : 'light-mode'}>
      <header>
        <h1>Статистика за положен возачки испит</h1>
        <button onClick={toggleDarkMode} className="toggle-button">
          {darkMode ? '🌙' : '☀️'}
        </button>
      </header>

      <div className="table-container">
        <div className="section">
          <h2>Неделна статистика</h2>
          <Table data={weeklyStats} />
        </div>

        <div className="section">
          <h2>Статистика за авто школи</h2>
          <Table data={averageAttempts} />
        </div>
      </div>
    </div>
  );
}

function Table({ data }) {
  if (data.length === 0) {
    return <p>Loading...</p>;
  }

  const headers = Object.keys(data[0]).filter((key) => key);

  return (
    <table>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header.trim()}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {headers.map((header, cellIndex) => (
              <td key={cellIndex}>{row[header]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default App;
